import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"
import Section from "components/section"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"

function BoxMain({ childPositionLeft, children, list }) {
  const leftItem = childPositionLeft ? children : list
  const rightItem = childPositionLeft ? list : children
  const firstColumnLenght = list ? "6" : "12"
  return (
    <Row className="mt-5 mx-auto" style={{ maxWidth: 1000 }}>
      <Col lg={firstColumnLenght}>{leftItem}</Col>
      <Col>{rightItem}</Col>
    </Row>
  )
}
BoxMain.propTypes = {
  children: PropTypes.node,
  childPositionLeft: PropTypes.bool,
  list: PropTypes.element,
}

export default function BoxSection({
  childPositionLeft,
  bgLight,
  headerText,
  descriptionText,
  listItems,
  containerStyle,
  children,
}) {
  const liItems =
    listItems &&
    listItems.map(item => (
      <li className="m-1 text-dark" key={item}>
        <FontAwesomeIcon
          className="mr-1"
          style={{ color: "#73b816" }}
          icon={faCheckSquare}
        />{" "}
        {item}
      </li>
    ))
  const list = listItems && <ul className="list-unstyled">{liItems}</ul>
  return (
    <Section
      bgLight={bgLight}
      containerStyle={{ paddingTop: 60, paddingBottom: 20, ...containerStyle }}
    >
      <div className="text-center ">
        <h1 className="font-weight-light text-muted mb-4">{headerText}</h1>
        <h4 className="font-weight-light text-muted">{descriptionText}</h4>
      </div>
      <BoxMain childPositionLeft={childPositionLeft} list={list}>
        {children}
      </BoxMain>
    </Section>
  )
}

BoxSection.propTypes = {
  children: PropTypes.node,
  bgLight: PropTypes.bool,
  childPositionLeft: PropTypes.bool,
  headerText: PropTypes.string,
  descriptionText: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  containerStyle: PropTypes.object,
}
