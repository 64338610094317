import Container from "react-bootstrap/Container"
import PropTypes from "prop-types"
import React from "react"

export default function Section({
  children,
  bgLight,
  divStyle,
  containerStyle,
}) {
  return (
    <div
      className={`${bgLight ? "border-top border-bottom bg-light" : ""}`}
      style={divStyle}
    >
      <Container
        className=""
        style={{ paddingBottom: 110, paddingTop: 100, ...containerStyle }}
      >
        {children}
      </Container>
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.node,
  bgLight: PropTypes.bool,
  divStyle: PropTypes.object,
  containerStyle: PropTypes.object,
}
