import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"
import styles from "./program.module.css"

export default function Top({
  headerText,
  headerBottomText,
  useFreeText,
  quoteText,
  quoteAuthor,
  children,
  buttonText,
  buttonLink,
  buttonBottomText,
  containerStyle,
  buttonColor,
}) {
  buttonColor = buttonColor ? buttonColor : "#28a745"
  return (
    <Container
      className=""
      style={{ paddingBottom: 110, paddingTop: 70, ...containerStyle }}
    >
      <h1 className="text-center" style={{ fontSize: 40 }}>
        {headerText}
      </h1>
      <h3
        className="font-weight-light text-center mt-5"
        style={{ fontSize: 24 }}
      >
        {headerBottomText}
      </h3>
      <div className="text-center text-muted mt-3">
        <small>
          <em>{useFreeText}</em>
        </small>
      </div>
      <Row className="mt-5">
        <Col lg="6">{children}</Col>
        <Col lg="6">
          <div className={styles.quote_box}>
            <div className={styles.quote_text}>
              <FontAwesomeIcon
                className={`${styles.quote_quote} ml-3`}
                icon={faQuoteLeft}
              />
              {quoteText}
              <FontAwesomeIcon
                className={styles.quote_quote}
                icon={faQuoteRight}
              />
            </div>
            <div className={styles.quote_author}>{quoteAuthor}</div>
            <div className="text-center mt-3">
              <a
                className="btn btn-success text-white w-100"
                href={buttonLink}
                style={{
                  padding: "10px 0px",
                  backgroundColor: buttonColor,
                }}
              >
                {buttonText}
              </a>
              <div
                className="text-left ml-4 text-muted mt-4"
                style={{ fontSize: 14 }}
              >
                {buttonBottomText}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
Top.propTypes = {
  headerText: PropTypes.string,
  headerBottomText: PropTypes.string,
  useFreeText: PropTypes.string,
  quoteText: PropTypes.string,
  quoteAuthor: PropTypes.string,
  children: PropTypes.node,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonBottomText: PropTypes.string,
  containerStyle: PropTypes.object,
  buttonColor: PropTypes.string,
}
