import BoxSection from "components/program/box-section"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import Top from "components/program/top"
import { graphql } from "gatsby"
import useTranslations from "components/use-translations"

export default function CekilisProgrami({ data }) {
  const {
    genel: { dahaFazlaBilgiMiGerekli, ozellikler, ekranGoruntuleri },
    cekilisProgram: {
      seoTitle,
      seoDescription,
      baslik,
      altBaslik,
      altBaslikAlti,
      kullaniciMesaji,
      kullaniciAdi,
      indirButonuYazi,
      butonAltiYazi,
      bolumler,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/cekilis-programi"
        datePublished="2017-02-09T21:36:44+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container>
        <Top
          headerText={baslik}
          headerBottomText={altBaslik}
          useFreeText={altBaslikAlti}
          quoteText={kullaniciMesaji}
          quoteAuthor={kullaniciAdi}
          buttonText={indirButonuYazi}
          buttonLink="/cekilis-programi/indir"
          buttonBottomText={butonAltiYazi}
          containerStyle={{ paddingBottom: 0 }}
        >
          <Img fluid={data.cekilisBilgisayar.childImageSharp.fluid} />
        </Top>
      </Container>
      <div className="my-5"></div>
      {/*  1. En Hızlı Çekiliş Sitesi*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[0].baslik}
        descriptionText={bolumler[0].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.cift.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 2. Çekilişinizi Kaydedin*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[1].baslik}
        descriptionText={bolumler[1].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.hiz.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 3.Farklı araçlar kullanın*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[2].baslik}
        descriptionText={bolumler[2].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.kaydet.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 2. Çekilişinizi Kaydedin*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[3].baslik}
        descriptionText={bolumler[3].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.gorsel.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 3.Farklı araçlar kullanın*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[4].baslik}
        descriptionText={bolumler[4].aciklama}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.toplu.childImageSharp.fluid} />
        </div>
      </BoxSection>

      <BoxSection containerStyle={{ paddingTop: 0 }}>
        <h5 className="text-center">{dahaFazlaBilgiMiGerekli}</h5>

        <ul className="list-unstyled text-center m-0" style={{ fontSize: 16 }}>
          <li className="mb-0">
            <LocalizedLink to="/cekilis-programi/ozellikler">
              {ozellikler}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/cekilis-programi/ekran-goruntuleri">
              {ekranGoruntuleri}
            </LocalizedLink>
          </li>
        </ul>
      </BoxSection>
    </Layout>
  )
}

export const query = graphql`
  query {
    cekilisBilgisayar: file(
      relativePath: { eq: "cekilis/cekilisplaptopta.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hiz: file(relativePath: { eq: "cekilis/hiz.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kaydet: file(relativePath: { eq: "cekilis/kaydet.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gorsel: file(relativePath: { eq: "cekilis/gorsel.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cift: file(relativePath: { eq: "cekilis/cift.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    toplu: file(relativePath: { eq: "cekilis/toplu.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

CekilisProgrami.propTypes = {
  data: PropTypes.object,
}
